import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home.js";
import Contact from "./pages/contact.js";
import Bookmarks from "./pages/bookmarks.js";
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/bookmarks" element={<Bookmarks/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
